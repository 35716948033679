import React from 'react';

import * as Styled from './styles';

import { colors } from '#styles.variables';
import { TypographySize, TypographyTag, TypographyWeight } from '#ui-kit.types';
import Typography from '../Typography';
import TableEmptyRow from './TableEmptyRow';
import TableHeader from './TableHeader';
import TableLoadingRow from './TableLoadingRow';
import Pagination from './TablePagination/Paginatiom';
import TableRow from './TableRow';
import { TableProps } from './types/Table.types';

const Table: React.FC<TableProps> = ({
  header,
  rows,
  footer,
  loading,
  preLoading = false,
  currentPage = 1,
  totalRows,
  pageSize = 10,
  onPageChange,
}) => (
  <Styled.TableWrapper preLoading={preLoading}>
    {!!totalRows && totalRows > 0 && (
      <Styled.TotalCounterWrapper>
        <Styled.TotalCounterContainer>
          <Typography
            size={TypographySize.s}
            tag={TypographyTag.span}
            weight={TypographyWeight.Light}
            style={{
              marginRight: '10px',
              lineHeight: '100%',
              color: colors.black._100,
            }}
          >
            Всего:
          </Typography>
          <Typography
            size={TypographySize.s}
            tag={TypographyTag.span}
            weight={TypographyWeight.Bold}
            style={{
              lineHeight: '100%',
              color: colors.black._100,
            }}
          >
            {totalRows || rows?.length}
          </Typography>
        </Styled.TotalCounterContainer>
      </Styled.TotalCounterWrapper>
    )}
    <Styled.Table>
      <thead>
        <TableHeader {...header} />
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <TableRow {...{ ...row, key: `tbody-${index}` }} />
        ))}
        <TableEmptyRow {...{ rows, header }} />
      </tbody>
      {footer && (
        <tfoot>
          <TableHeader {...footer} />
        </tfoot>
      )}
      {loading && (
        <tfoot>
          <TableLoadingRow {...{ header }} />
        </tfoot>
      )}
    </Styled.Table>
    {!!totalRows && !!onPageChange && totalRows > pageSize && (
      <Pagination
        currentPage={currentPage}
        totalRows={totalRows}
        onPageChange={onPageChange}
        pageSize={pageSize}
      />
    )}
  </Styled.TableWrapper>
);

export default Table;
