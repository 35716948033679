import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from '#ui-kit';
import { Head, LoadingContent } from '#components';
import { HeadUnderlineVariant } from '#components.types';
import { removeEmptyFields } from '#utils';
import debug from '#services/debug.service';
import { OrdersService } from '#services/orders';
import { GetOrdersDto } from '#services/orders/dto/get-orders.dto';
import { OrdersType } from '#services/orders/responses/orders.response';
import { FilterForm } from './containers/FilterForm';
import Form from './containers/Table';
import queryString from 'query-string';

export default () => {
  const ordersService = new OrdersService();

  const location = useLocation();
  const navigate = useNavigate();

  // Парсим параметры из URL
  const queryParams = useMemo(
    () => queryString.parse(location.search, { arrayFormat: 'bracket' }),
    [location.search],
  );

  const [page, setPage] = useState<number>(() => Number(queryParams.page) || 1);
  const [size, setSize] = useState<number>(
    () => Number(queryParams.size) || 10,
  );
  const [orders, setOrders] = useState<OrdersType>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [filters, setFilters] = useState<Omit<GetOrdersDto, 'page' | 'size'>>({
    createdAtSort: 'desc',
    ...queryParams,
  });

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const getOrders = async () => {
    setLoading(true);
    try {
      const response = await ordersService.getOrders({
        ...filters,
        page,
        size,
      });
      setOrders(response.data);
      setTotalRows(response.total);
    } catch (e) {
      debug.error('🧑🏻‍💻 ~ getOrders ~ err:', e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const newQueryParams = {
      ...filters,
      page,
      size,
    };

    const search = queryString.stringify(newQueryParams, {
      arrayFormat: 'bracket',
    });

    navigate(
      {
        pathname: location.pathname,
        search: `?${search}`,
      },
      { replace: true },
    );
  }, [filters, page, size]);

  useEffect(() => {
    getOrders();
  }, [filters, page, size]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setPage(1);
    setFilters((prevFilters) =>
      removeEmptyFields({
        ...prevFilters,
        [name]: value?.length ? value : undefined,
      }),
    );
  };

  const handleCheckboxGroupChange = (
    name: string,
    selectedValues: string[],
  ) => {
    setPage(1);
    setFilters((prevFilters) =>
      removeEmptyFields({
        ...prevFilters,
        [name]: selectedValues,
      }),
    );
  };

  const handleDeleteSelectedErrorCategory = (id: number) => {
    setPage(1);
    setFilters((prevFilters) => {
      const filtered = prevFilters.errorCategoryIds?.filter(
        (categoryId) => categoryId !== id,
      );

      return removeEmptyFields({
        ...prevFilters,
        errorCategoryIds: filtered,
      });
    });
  };

  const handleDeleteSelectedError = (name: string) => {
    setPage(1);
    setFilters((prevFilters) => {
      const filtered = prevFilters.errorMessageCodes?.filter(
        (error) => error !== name,
      );

      return removeEmptyFields({
        ...prevFilters,
        errorMessageCodes: filtered,
      });
    });
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setPage(1);
    setFilters((prevFilters) => ({
      ...prevFilters,
      page,
      size,
    }));
  };

  const onPageChange = (pageNumber: number, pageSize: number) => {
    setPage(pageNumber);
    setSize(pageSize);
  };

  return (
    <>
      <Head
        {...{
          label: 'Заказы',
          underlineVariant: HeadUnderlineVariant.Primary,
          button: {
            label: 'Фильтрация',
            onClick: handleOpenModal,
          },
        }}
      />
      {/* Форма фильтров */}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <FilterForm
          handleCheckboxGroupChange={handleCheckboxGroupChange}
          handleFormSubmit={handleFormSubmit}
          handleInputChange={handleInputChange}
          handleDeleteSelectedError={handleDeleteSelectedError}
          handleDeleteSelectedErrorCategory={handleDeleteSelectedErrorCategory}
          filters={filters}
        />
      </Modal>
      {loading ? (
        <LoadingContent />
      ) : (
        <Form
          data={orders}
          pageSize={size}
          loading={loading}
          currentPage={page}
          totalRows={totalRows}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
};
